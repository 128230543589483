import { Controller } from "react-hook-form";
import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import { FormInputProps } from "./FormInputProps";

interface Option {
  label: string;
  id: number;
}

export const FormInputSelect = ({ name, control, label, errors, options }: FormInputProps) => {
  const [items, setItems] = useState<Option[]>([]);

  useEffect(() => {
    if (options && options.length) {
      setItems(options);
    }
  }, [options]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Autocomplete
          disablePortal
          options={items}
          getOptionLabel={(option: Option) => option.label} // Display label
          isOptionEqualToValue={(option, value) => option.id === value?.id} // Ensure equality
          value={value || null} // Ensure value is always controlled, default to null
          onChange={(event, newValue) => {
            onChange(newValue); // Update form value with new selection
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              size="small"
              error={!!error}
              helperText={error ? error.message : null}
              fullWidth
            />
          )}
        />
      )}
    />
  );
};
