import { useState, useEffect, MouseEvent } from 'react';
import { IActivityQuestion } from 'models/IActivityQuestion';
import { IOption } from 'models/IOption';
import { makeStyles } from '@mui/styles'
import {
  TextField,
  Button,
  List,
  ListItem,
  FormControlLabel,
  Switch,
  ListItemText,
  ListItemButton,
} from '@mui/material';
import {
  Save as SaveIcon,
  DeleteForever as DeleteForeverIcon,
  Star as StarIcon,
  StarBorder as StarBorderIcon,
  Edit as EditIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material';

import Grid from '@mui/material/Grid2';
import { styled } from '@mui/system';

const FormGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const useStyles = makeStyles(theme => ({
  gridItem: {
    alignSelf: 'center',
  },
  optionList: {
    border: '2px solid black',
    borderRadius: '10px',
  },
}));

interface IProps {
  option: IOption;
  activityQuestion: IActivityQuestion;
  handleChange: (e: EventTarget, activity?: boolean, option?: boolean) => void;
  disableAdd: boolean;
  handleAddOption: () => void;
  allOptionsCorrect: boolean;
  handleAllOptionsCorrectToggle: () => void;
  selectedIndex: number;
  handleCorrectOptionSelected: (
    e: MouseEvent<HTMLDivElement>,
    id: number | null
  ) => void;
  editingOption: { id: number; edit: boolean };
  handleOptionInputChange: (e: EventTarget) => void;
  handleOptionUpdate: (
    e: MouseEvent<HTMLButtonElement>,
    option: IOption
  ) => void;
  handleOptionEdit: (e: MouseEvent<HTMLButtonElement>, option: IOption) => void;
  handleOptionCancelUpdate: (e: MouseEvent<HTMLButtonElement>) => void;
  handleOptionDelete: (
    e: MouseEvent<HTMLButtonElement>,
    option: IOption
  ) => void;
}


function MultiChoiceActivityForm(props: IProps) {
  const classes = useStyles();
  const [correctOption, setCorrectOption] = useState<number | null>(null);

  useEffect(() => {
    if (props?.activityQuestion !== null) {
      setCorrectOption(props.activityQuestion.AnswerPosition);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.selectedIndex !== null) {
      setCorrectOption(props.selectedIndex);
    }
  }, [props.selectedIndex]);

  return (
    <>
      <Grid container spacing={3} mt={2} >
        <FormGrid size={{ xs: 3 }}>
          <TextField
            id='waypoint-questionText'
            label='Option Text'
            name='OptionText'
            helperText=''
            variant='outlined'
            value={props.option.OptionText}
            onChange={e => props.handleChange(e.target, false, true)}
            disabled={props.disableAdd}
            fullWidth
          />
        </FormGrid>
        <FormGrid size={{ xs: 4 }}>
          <TextField
            id='waypoint-responseText'
            label='Option Response'
            name='ResponseText'
            helperText=''
            variant='outlined'
            value={props.option.ResponseText}
            onChange={e => props.handleChange(e.target, false, true)}
            disabled={props.disableAdd}
            fullWidth
          />
        </FormGrid>
        <FormGrid size={{ xs: 2 }}>
          <Button
            variant='contained'
            color='primary'
            onClick={props.handleAddOption}
            disabled={
              props.disableAdd || !props.option?.OptionText?.trim()
            }
          >
            Add Option
          </Button>
        </FormGrid>
        <FormGrid size={{ xs: 3 }}>
          <FormControlLabel
            control={
              <Switch
                checked={props.allOptionsCorrect}
                onChange={props.handleAllOptionsCorrectToggle}
                name='options'
                color='primary'
              />
            }
            label='Set all as correct'
            labelPlacement='top'
          />
        </FormGrid>
      </Grid>
      <Grid container spacing={2} columns={12} justifyContent='center' alignItems='center'>
        {/* OPTIONS LIST */}
        {props?.activityQuestion.MultiChoiceOptions && props?.activityQuestion.MultiChoiceOptions.length > 0 ? (
          <Grid size={12} /*xs={12}*/ className={classes.optionList}>
            <List component='nav' aria-label='activity options'>
              {props.activityQuestion.MultiChoiceOptions.map((option: IOption) => (
                <ListItem
                  key={option.OptionId}
                  disablePadding
                >
                  <ListItemButton
                    selected={correctOption === option.Position || props.allOptionsCorrect}
                    onClick={(e) =>
                      props.handleCorrectOptionSelected(e, option.Position)
                    }>
                    {correctOption === option.Position ||
                      props.allOptionsCorrect ? (
                      <StarIcon />
                    ) : (
                      <StarBorderIcon />
                    )}

                    {props.editingOption.id === option.OptionId &&
                      props.editingOption.edit ? (
                      <TextField
                        id='option-text-edit'
                        name='text'
                        placeholder={option.OptionText}
                        onChange={e => props.handleOptionInputChange(e.target)}
                        style={{ flex: '1 1 auto' }}
                      />
                    ) : (
                      <ListItemText primary={option.OptionText} />
                    )}

                    {props.editingOption.id === option.OptionId &&
                      props.editingOption.edit ? (
                      <TextField
                        id='option-response-edit'
                        name='response'
                        placeholder={option.ResponseText}
                        onChange={e => props.handleOptionInputChange(e.target)}
                        style={{ flex: '1 1 auto' }}
                      />
                    ) : (
                      <ListItemText primary={option.ResponseText} />
                    )}

                    {props.editingOption.id === option.OptionId &&
                      props.editingOption.edit ? (
                      <Button
                        color='primary'
                        onClick={e => props.handleOptionUpdate(e, option)}
                      >
                        <SaveIcon />
                      </Button>
                    ) : (
                      <Button
                        color='primary'
                        onClick={e => props.handleOptionEdit(e, option)}
                      >
                        <EditIcon />
                      </Button>
                    )}

                    {props.editingOption.id === option.OptionId &&
                      props.editingOption.edit ? (
                      <Button
                        color='secondary'
                        onClick={e => props.handleOptionCancelUpdate(e)}
                      >
                        <CancelIcon />
                      </Button>
                    ) : (
                      <Button
                        color='secondary'
                        onClick={e => props.handleOptionDelete(e, option)}
                      >
                        <DeleteForeverIcon />
                      </Button>
                    )}
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
}

export default MultiChoiceActivityForm;
