import React, { useState, useContext, useEffect } from "react";
import { navigate } from "hookrouter";

import AuthContext from "context/Auth/auth";
import Copyright from "components/common/Copyright";
import { URLS } from "urls";
import { isValidEmail } from "helpers/Validations";
import tkIcon from "../../../images/TRAKID_Mobile.png";

import { makeStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Button,
  CssBaseline,
  TextField,
  Box,
  Typography,
  Container,
  CircularProgress,
} from "@mui/material";

import Grid from "@mui/material/Grid2";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    height: '250px',
  },
  paper: {
    marginTop: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "56px",
    borderRadius: "10px",
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
  },
  logoImg: {
    marginLeft: "1px",
    marginTop: "-2px",
    marginRight: "-1px",
  },
  header: {
    marginTop: theme.spacing(3),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  status: {
    textAlign: "center",
    margin: theme.spacing(1),
  },
  formSubmit: {
    justifyContent: "center",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function SignIn() {
  const context = useContext(AuthContext);
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({
    color: "black",
    response: "",
  });

  useEffect(() => {
    if (context.token) {
      navigate(URLS.OVERVIEW);
    }

    if (context.error) {
      setStatus({
        color: "red",
        response: context.error,
      });
      setLoading(false);
    }
  }, [context.token, context.error, context.attempts]); // eslint-disable-line react-hooks/exhaustive-deps

  function validateForm() {
    const validEmail = isValidEmail(email);

    return validEmail && password.length > 0;
  }

  function handleSubmit(e) {
    e.preventDefault();

    setStatus({
      color: "blue",
      response: "Validating",
    });

    context.login(email, password);
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={tkIcon} alt="TRAKID Compass" />

        <Typography component="h1" variant="h5" className={classes.header}>
          Sign in
        </Typography>
        <Box sx={{ flexGrow: 1 }} className={classes.root}>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h5" className={classes.status}>
                  {status.response}
                </Typography>
              </Grid>
              {loading ? (
                  <CircularProgress />
                ) : (
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={!validateForm()}
                  >
                    Sign In
                  </Button>
                )}
            </Grid>
          </form>
        </Box>
      </div>

      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default SignIn;
