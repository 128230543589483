import { IActivity } from 'models/IActivity';

import { TextField, FormControlLabel, Switch } from '@mui/material';

import Grid from '@mui/material/Grid2';
import { styled } from '@mui/system';

const FormGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));


interface IProps {
  activity: IActivity;
  handleChange: (e: EventTarget, b: boolean) => void;
  allowAnyText: boolean;
  allowAnyTextToggled: () => void;
}
function OpenTextActivityForm(props: IProps) {
  return (
    <>
      <Grid container spacing={3} mt={2} >
        <FormGrid size={{ xs: 4 }}>
          <TextField
            id='waypoint-placeholder'
            label='Placeholder'
            name='PlaceholderText'
            helperText=''
            variant='outlined'
            value={props.activity.PlaceholderText}
            onChange={e => props.handleChange(e.target, true)}
          />
        </FormGrid>
        <FormGrid size={{ xs: 4 }}>
          <TextField
            id='waypoint-answer'
            label='Answer'
            name='Answer'
            helperText=''
            variant='outlined'
            value={props.activity.Answer}
            onChange={e => props.handleChange(e.target, true)}
            disabled={props.allowAnyText}
          />
        </FormGrid>
        <FormGrid size={{ xs: 4 }}>
          <FormControlLabel
            control={
              <Switch
                checked={props.allowAnyText}
                onChange={props.allowAnyTextToggled}
                name='status'
                color='primary'
              />
            }
            label='Allow any text to be correct answer'
          />
        </FormGrid>
      </Grid>
    </>
  );
}

export default OpenTextActivityForm;
