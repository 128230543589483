import { Tutorial } from 'models/Tutorial';

import { makeStyles } from '@mui/styles'
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';


import gameStatesBg from '../../../../../images/cms/blank-screens/how-to-play-4.png';
import defaultImg from '../../../../../images/cms/welcome/park-logo-placeholder.png';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    justifyItems: 'center',
    background: `url(${gameStatesBg}) no-repeat center`,
    backgroundSize: 'contain',
    // height: '700px',
    height: '654px',

    '& > *': {
      boxSizing: 'content-box',
      // border: '1px solid red',
      // width: '320px',
    },
  },
  headerText: {
    color: '#FFFFFF',
    textAlign: 'center',
    marginTop: '30px',
    marginBottom: 0,

    '& > h3': {
      fontSize: '1.4rem',
    },
  },
  description: {
    color: '#000000',
    textAlign: 'left',
    marginLeft: 2,
    fontSize: '1.3rem',
    maxWidth: '300px',
    marginBottom: '10px',
    marginTop: '5px',
    width: '300px',

    '& > h3': {
      fontSize: '1.35rem',
      fontWeight: 'bolder',
    },

    '& > p': {
      fontSize: '16px',
    },
  },
  compassImage: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    maxWidth: '320px',
    width: '302px',
    height: '350px',
    marginTop: '80px',
    overflow: 'hidden',
  },
  image: {
    position: 'relative',
    left: '50px',
    height: '150px',
    width: '150px',
    borderRadius: '100%',
  },
}));

interface Props {
  tutorial: Tutorial;
  compassImage: string | null;
}
function EngagePreview({ tutorial, compassImage }: Props) {
  const classes = useStyles();

  return (
    <>
      <Box sx={{ flexGrow: 1 }} className={classes.root} >
        <Grid container alignItems="center"
          justifyContent="center">
          <Grid className={classes.headerText} size={12}>
            {tutorial.StateText3 !== '' ? (
              <h3>{tutorial.StateText3}</h3>
            ) : (
              <h3>Help</h3>
            )}
          </Grid>

          <Grid className={classes.description} size={12}>
            {tutorial.Title3 !== '' ? (
              <h3>{tutorial.Title3}</h3>
            ) : (
              <h3>
                <em>Title Placeholder</em>
              </h3>
            )}

            {tutorial.Description3 !== '' ? (
              <p>{tutorial.Description3}</p>
            ) : (
              <p>
                <em>Description Placeholder</em>
              </p>
            )}
          </Grid>

          <Grid className={classes.compassImage} size={12}>
            {compassImage !== null ? (
              <img
                src={compassImage}
                alt='Compass Imagery'
                className={classes.image}
              />
            ) : (
              <img
                src={defaultImg}
                alt='Default Imagery'
                className={classes.image}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default EngagePreview;
