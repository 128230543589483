import { Tutorial } from 'models/Tutorial';

import { makeStyles } from '@mui/styles'
import Grid from '@mui/material/Grid2';
import gameStatesBg from '../../../../../images/cms/blank-screens/how-to-play-1.png';
import Box from '@mui/material/Box';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    background: `url(${gameStatesBg}) no-repeat center`,
    backgroundSize: 'contain',
    height: '700px',

    '& > *': {
      boxSizing: 'content-box',
      width: '320px',
    },
  },
  headerText: {
    color: '#FFFFFF',
    textAlign: 'center',
    marginTop: 32,
    marginBottom: 0,
    '& > h3': {
      fontSize: '1.4rem',
    },
  },
  description: {
    color: '#000000',
    textAlign: 'left',
    fontSize: '1.3rem',
    maxWidth: '300px',
    padding: '0 10px',
    marginTop: 10,
    marginBottom: 10,
    '& > h3': {
      fontSize: '1.35rem',
      fontWeight: 'bolder',
    },

    '& > p': {
      fontSize: '16px',
    },
  },
  gameState: {
    color: '#FFFFFF',
    marginLeft: 20,
    textAlign: 'left',
    marginTop: 12,
    marginBottom: 82,
    '& > h3': {
      fontSize: '1.2rem',
    },
  },
}));

interface Props {
  tutorial: Tutorial;
}
function GameStatesPreview({ tutorial }: Props) {
  const classes = useStyles();

  return (
    <>
      <Box sx={{ flexGrow: 1 }} className={classes.root} >
        <Grid container alignItems="center"
          justifyContent="center">
          <Grid className={classes.headerText} size={12}>
            <h3>How to play</h3>
          </Grid>
          <Grid className={classes.description} size={12} >
            <h3>Your adventure starts here!</h3>
            <p>Every adventure requires these steps!</p>
          </Grid>
          <Grid className={classes.gameState} size={12}>
            {tutorial.StateText1 !== '' ? (
              <h3>{tutorial.StateText1}</h3>
            ) : (
              <h3>State 1</h3>
            )}
          </Grid>

          <Grid className={classes.gameState} size={12}>
            {tutorial.StateText2 !== '' ? (
              <h3>{tutorial.StateText2}</h3>
            ) : (
              <h3>State 2</h3>
            )}
          </Grid>

          <Grid className={classes.gameState} size={12}>
            {tutorial.StateText3 !== '' ? (
              <h3>{tutorial.StateText3}</h3>
            ) : (
              <h3>State 3</h3>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default GameStatesPreview;
