import { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { useCookies } from "react-cookie";
import { A } from "hookrouter";
import { UserContext } from "context/UserContext";
import { hasAuthorization } from "helpers/HasAuthorization";
import brand from "images/TRAKID_White.png";
import { getOrg, getOrganizations } from "services/OrgsService";
import Organization from "models/Organization";
import { fetchParksForOrg } from "services/Parks.Service";
import Park from "models/Park";
import { getEnvironment } from "config";
import { makeStyles } from "@mui/styles";
import {
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import MoreIcon from "@mui/icons-material/MoreVert";
import StorageIcon from "@mui/icons-material/Storage";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";

import * as React from "react";
import Avatar from "@mui/material/Avatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: 240,
    width: `calc(100% - 240px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

function PrimaryAppBar({ hidden, adminProps, shift, handleDrawerOpen, open }) {
  const classes = useStyles();
  const [cookies] = useCookies(["token"]);
  const { state, dispatch } = useContext(UserContext);
  const currentOrgId = useSelector((state) => state.organization.orgId);

  const [organizations, setOrganizations] = useState([]);
  const [parks, setParks] = useState([]);
  const [selectedPark, setSelectedPark] = useState(null);

  const [parkSelectDisabled, setParkSelectDisabled] = useState(true);
  const [orgSelectDisabled, setOrgSelectDisabled] = useState(false);

  const [parkMenuAnchor, setParkMenuAnchor] = useState(null);
  const [accountMenuAnchor, setAccountMenuAnchor] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isParkMenuOpen = Boolean(parkMenuAnchor);
  const isAccountMenuOpen = Boolean(accountMenuAnchor);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleParkMenuOpen = (event) => {
    setParkMenuAnchor(event.currentTarget);
  };

  const handleProfileMenuOpen = (event) => {
    setAccountMenuAnchor(event.currentTarget);
  };

  const confirmMenuClose = () => {
    adminProps.setSelectedPark(selectedPark);
    handleMenuClose();
  };

  const handleMenuClose = () => {
    setAccountMenuAnchor(null);
    setParkMenuAnchor(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //#region Park Picker
  const parkPicker = "primary-park-picker-menu";
  const renderParkMenu = (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={isParkMenuOpen}
      onClose={handleMenuClose}
    >
      <DialogTitle>Select Park</DialogTitle>
      <DialogContent>
        <form className={classes.container}>
          <Box
            component="form"
            sx={{ "& .MuiTextField-root": { m: 1, width: "26ch" } }}
            noValidate
            autoComplete="off"
          >
            <div>
              <TextField
                id="outlined-select-organization"
                select
                //disabled
                label="Organizations"
                value={adminProps.selectedOrg ? adminProps.selectedOrg.Id : ""}
                onChange={(e) =>
                  adminProps.setSelectedOrg(
                    organizations.find((org) => org.OrgId === e.target.value) ||
                      null
                  )
                }
                helperText="Please select an organization"
                slotProps={{
                  select: {
                    MenuProps: {
                      sx: { maxHeight: "50%", width: "100px" },
                    },
                  },
                }}
              >
                {organizations.map((option) => (
                  <MenuItem key={option.OrgId} value={option.OrgId}>
                    {option.Name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="outlined-select-park"
                select
                label="Parks"
                value={selectedPark ? selectedPark.ParkId : ""}
                helperText="Please select a park"
                onChange={(e) =>
                  setSelectedPark(
                    parks.find((park) => park.ParkId === e.target.value) || null
                  )
                }
                slotProps={{
                  select: {
                    MenuProps: {
                      sx: { maxHeight: "50%", width: "100px" },
                    },
                  },
                }}
              >
                {parks.map((option) => (
                  <MenuItem key={option.ParkId} value={option.ParkId}>
                    {option.Name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </Box>
        </form>
        {orgSelectDisabled && parkSelectDisabled ? (
          <p>
            <em>Contact your team administrator to add you to a park.</em>
          </p>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleMenuClose} color="primary">
          Cancel
        </Button>
        <Button onClick={confirmMenuClose} color="primary">
          Set Park
        </Button>
      </DialogActions>
    </Dialog>
  );
  //#endregion

  //#region Account
  const menuId = "primary-account-menu";
  const renderAccountMenu = (
    <>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openMenu}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <A href="/profile" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MenuItem onClick={handleClose}>
            <Avatar /> Profile
          </MenuItem>
        </A>
        <Divider />
        <A href="/logout" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </A>
      </Menu>
    </>
  );
  //#endregion

  const mobileMenuId = "primary-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleParkMenuOpen}>
        <IconButton
          aria-label="park selection"
          color="inherit"
          aria-controls={parkPicker}
          aria-haspopup="true"
        >
          <AccountTreeIcon />
        </IconButton>
        <span>Parks</span>
      </MenuItem>
      <MenuItem>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <A href="/profile">
          <span>Profile</span>
        </A>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="sign out of current user" color="inherit">
          <ExitToAppIcon />
        </IconButton>
        <A href="/logout">
          <span onClick={handleMenuClose}>Logout</span>
        </A>
      </MenuItem>
    </Menu>
  );

  useEffect(() => {
    if (state.orgId !== null) {
      if (!hasAuthorization(state.userRoles, [1, 2])) {
        console.log("here");
        setOrgSelectDisabled(true);
      }

      getOrg(cookies, state.orgId).then((response) => {
        adminProps.setSelectedOrg(response.data);
      });

      if (state.accessToParks.length > 0) {
        fetchParksForOrg(cookies, state.orgId).then((data) => {
          if (typeof data === "undefined") {
            return;
          } else {
            const parkList = data.map((park) => {
              return new Park(
                park.ParkId,
                park.Name,
                park.Address,
                park.City,
                park.State,
                park.Zip,
                park.Latitude,
                park.Longitude,
                park.Zoom
              );
            });
            //const filteredParks = parkList.filter(park => state.accessToParks.includes(park.ParkId));
            setParks(parkList);

            if (state.accessToParks.length > 1) {
              setParkSelectDisabled(false);
            }

            setSelectedPark(parkList[0]);
            adminProps.setSelectedPark(parkList[0]);
          }
        });
      }
    }
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (cookies.token) {
      getOrganizations(cookies).then((data) => {
        if (typeof data === "undefined") {
          return;
        } else {
          const organizationList = data.map((org) => {
            return new Organization(
              org.OrgId,
              org.Name,
              org.Address,
              org.City,
              org.State,
              org.Zip,
              org.ContactEmail,
              org.ContactPhoneNumber
            );
          });
          setOrganizations(organizationList);
        }
      });
    }
  }, [cookies]);

  useEffect(() => {
    if (adminProps.selectedOrg) {
      fetchParksForOrg(cookies, adminProps.selectedOrg.Id).then((data) => {
        if (typeof data === "undefined") {
          return;
        } else {
          const parkList = data.map((park) => {
            return new Park(
              park.ParkId,
              park.Name,
              park.Address,
              park.City,
              park.State,
              park.Zip,
              park.Latitude,
              park.Longitude,
              park.Zoom
            );
          });

          //const filteredParks = parkList.filter(park => state.accessToParks.includes(park.ParkId));
          setParks(parkList);

          if (state.accessToParks.length > 0) {
            setParkSelectDisabled(false);
          }
        }
      });
    }
  }, [adminProps.selectedOrg]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (currentOrgId) {
      adminProps.setSelectedOrg(
        organizations.find((org) => org.OrgId === currentOrgId) ||
          null
      )
      console.log('useEffect', currentOrgId);
    }
  }, [currentOrgId]);

  return (
    <>
      {hidden ? null : (
        <>
          <AppBar position="fixed" open={open}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={[
                  {
                    marginRight: 5,
                  },
                  open && { display: "none" },
                ]}
              >
                <MenuIcon />
              </IconButton>
              <Typography className={classes.title} variant="h6" noWrap>
                <img src={brand} height="50px" alt="TraKid Company Logo" />
              </Typography>

              {/* Environment Internal */}
              <Typography className={classes.title} variant="subtitle1" noWrap>
                <span hidden={getEnvironment() !== "STAGING"}>
                  <StorageIcon /> STAGING
                </span>
                <span hidden={getEnvironment() !== "QUALITY"}>
                  <StorageIcon /> QUALITY
                </span>
                <span hidden={getEnvironment() !== "DEVELOPMENT"}>
                  <StorageIcon /> DEVELOPMENT
                </span>
                <span hidden={getEnvironment() !== "LOCAL"}>
                  <StorageIcon /> LOCAL
                </span>
              </Typography>

              <div className={classes.grow} />
              <Typography className={classes.title} variant="subtitle1" noWrap>
                {adminProps.selectedPark
                  ? `Current Park: ${adminProps.selectedPark.Name}`
                  : null}
              </Typography>

              <div className={classes.sectionDesktop}>
                <IconButton
                  aria-label="park selection"
                  color="inherit"
                  aria-controls={parkPicker}
                  aria-haspopup="true"
                  onClick={handleParkMenuOpen}
                >
                  <AccountTreeIcon />
                </IconButton>

                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={openMenu ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? "true" : undefined}
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                </Tooltip>
              </div>

              <div className={classes.sectionMobile}>
                <IconButton
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
        </>
      )}

      {renderMobileMenu}
      {renderAccountMenu}
      {renderParkMenu}
    </>
  );
}

PrimaryAppBar.propTypes = {
  hidden: PropTypes.bool,
  adminProps: PropTypes.object,
  shift: PropTypes.bool,
};

export default PrimaryAppBar;
