import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';



export default function FormLoader() {
    return (
        <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between', position: 'relative' }}>
            <Box
                component="form"
                sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 1, position: 'relative', top: 70 }}
            >
                {/* For variant="text", adjust the height via font-size */}
                <Divider textAlign="left">
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={150} />
                </Divider>
                <Skeleton variant="rounded" height={60} animation="wave"/>
                <Skeleton variant="rounded" height={60} />
                <Divider textAlign="left">
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={150} />
                </Divider>
                <Skeleton variant="rounded" height={60} animation="wave"/>
                <Skeleton variant="rounded" height={60} />
                <Skeleton variant="rounded" width={100} height={60} animation="wave"/>
            </Box>
        </Stack>
    );
}