import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import { FormInputProps } from "./FormInputProps";

export const FormInputMultipleAutocomplete = ({
  name,
  control,
  label,
  errors,
  options,
}: FormInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <Autocomplete
            multiple
            limitTags={2}
            id={name}
            value={value || []} 
            onChange={(event, newValue) => onChange(newValue)} 
            options={options || []} 
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.id === value.id} 
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </>
      )}
    />
  );
};
