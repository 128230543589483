import { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { useCookies } from "react-cookie";
import { usePath, navigate } from "hookrouter";
import imageCompression from "browser-image-compression";
import { URLS } from "urls";
import TabPanel from "components/common/TabPanel";
import A11yProps from "components/common/A11yProps";
import Alert from "components/common/Alert";
import MapPanel from "components/common/MapPanel";
import FormSelect from "../common/FormSelect";

import AuthContext from "context/Auth/auth";
import WaypointMap from "../maps/WaypointMap";
import ConfirmationDialog from "helpers/ConfirmationDialog";

import ConfirmationActivityForm from "../forms/ConfirmationActivityForm";
import OpenTextActivityForm from "../forms/OpenTextActivityForm";
import MultiChoiceActivityForm from "../forms/MultiChoiceActivityForm";

import WaypointLocationForm from "../forms/WaypointLocationForm";
import WaypointDetailsForm from "../forms/WaypointDetailsForm";
import WaypointActivityForm from "../forms/WaypointActivityForm";
import ActivityDescriptions from "../forms/ActivityDescriptions";

import DetailPreview from "../previews/DetailPreview";
import ConfirmationPreview from "../previews/ConfirmationPreview";
import MultiChoicePreview from "../previews/MultiChoicePreview";
import TextEntryPreview from "../previews/TextEntryPreview";

import {
  addPoint,
  fetchNewPoint,
  fetchNewQuestion,
  fetchNewQuestionOption,
  getPoint,
  getPoints,
  updatePoint,
  deletePoint,
} from "services/Waypoint.Service";
import { getZone } from "services/ZonesService";
import { getImage } from "services/Image.Service";
import { ImageType } from "helpers/EnumHelper";

import { makeStyles } from "@mui/styles";
import {
  Paper,
  Grid,
  Tabs,
  Tab,
  Button,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import {
  Save as SaveIcon,
  Delete as DeleteIcon,
  Error as ErrorIcon,
} from "@mui/icons-material";

import { default as Grid2 } from "@mui/material/Grid2";
import { styled } from "@mui/system";

const FormGrid = styled(Grid2)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const FORM_FIELDS = {
  LATITUDE: "Latitude",
  LONGITUDE: "Longitude",
  RADIUS: "Radius",
  SHORTNAME: "ShortName",
};

const ERROR_MSG = {
  BLANK: "Field cannot be Blank",
  INVALID: "Invalid for Zone",
  LATITUDE_VALIDATION: "A valid latitude is between -90 and 90",
};

function WaypointCMS(props) {
  const authContext = useContext(AuthContext);
  const [cookies, setCookies, removeCookies] = useCookies(["selected-park"]);
  const zoneId = props.zoneId;
  const path = usePath();
  const classes = useStyles();

  const [currentWaypoint, setCurrentWaypoint] = useState({});
  const [currentWaypointPosition, setCurrentWaypointPosition] = useState({
    Latitude: 0,
    Longitude: 0,
  });
  const [currentRadius, setCurrentRadius] = useState(20);
  const [currentProximity, setCurrentProximity] = useState(0);
  const [currentActivity, setCurrentActivity] = useState({});
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [currentOption, setCurrentOption] = useState({});
  const [disableAddOptions, setDisableAddOptions] = useState(false);
  const [allWaypoints, setAllWaypoints] = useState([]);
  const [zone, setZone] = useState({});
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [editingOption, setEditingOption] = useState({ id: null, edit: false });
  const [tempOption, setTempOption] = useState({ text: "", response: "" });
  const [isNewWaypoint, setIsNewWaypoint] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [colorPickerEl, setColorPickerEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedAllOptions, setSelectedAllOptions] = useState(false);
  const [allowOpenAnswer, setAllowOpenAnswer] = useState(false);

  const [activityChanged, setActivityChanged] = useState(false);

  // Errors
  const [locationFormError, setLocationFormError] = useState(false);
  const [detailFormError, setDetailFormError] = useState(false);
  const [activityFormError, setActivityFormError] = useState(false);
  const [shortNameError, setShortNameError] = useState(false);
  const [radiusError, setRadiusError] = useState({ show: false, msg: "" });
  const [proximityError, setProximityError] = useState({
    show: false,
    msg: "",
  });
  const [latitudeError, setLatitudeError] = useState({ show: false, msg: "" });
  const [longitudeError, setLongitudeError] = useState({
    show: false,
    msg: "",
  });

  // Snackbar
  const [openSnack, setOpenSnack] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState("success");
  const [snackMessage, setSnackMessage] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleListItemClick = (event, optionPosition) => {
    setSelectedIndex(optionPosition);

    const question = currentQuestion;
    question.MultiChoiceOptions.forEach((option) => {
      option.IsAnswer = false;
    });

    const option = question.MultiChoiceOptions.find(
      (option) => option.Position === optionPosition
    );
    option.IsAnswer = true;

    setCurrentQuestion(question);
  };

  const handleOptionEdit = (e, option) => {
    e.stopPropagation();
    setEditingOption({ id: option.OptionId, edit: true });
  };

  const handleOptionInputUpdate = ({ name, value }) => {
    setTempOption({ ...tempOption, [name]: value });
  };

  const handleOptionUpdate = (e, option) => {
    e.stopPropagation();

    if (tempOption.text && tempOption.text !== option.OptionText) {
      option.OptionText = tempOption.text;
    }

    // Check if the tempOption response is different from the current ResponseText and not empty
    if (tempOption.response && tempOption.response !== option.ResponseText) {
      option.ResponseText = tempOption.response;
    }

    const options = currentQuestion.MultiChoiceOptions;
    const optionIndex = options.findIndex(
      (opt) => opt.OptionId === option.OptionId
    );
    options[optionIndex] = option;

    setCurrentQuestion({ ...currentQuestion, Options: options });
    setTempOption({ text: "", response: "" });
    setEditingOption({ id: "", edit: false });
  };

  const handleOptionCancelUpdate = (e) => {
    e.stopPropagation();

    setEditingOption({ id: "", edit: false });
    setTempOption({ text: "", response: "" });
  };

  const handleOptionDelete = (e, option) => {
    e.stopPropagation();

    let filtered = currentQuestion.MultiChoiceOptions.filter(
      (opt) => opt.OptionId !== option.OptionId
    );

    let newPosition = 0;
    filtered = filtered.map((option) => {
      option.Position = newPosition;
      newPosition++;

      return option;
    });

    if (filtered.length < 4) {
      setDisableAddOptions(false);
    }

    setCurrentQuestion({ ...currentQuestion, MultiChoiceOptions: filtered });
  };

  const handleOptionAdd = () => {
    // Ensure currentQuestion.MultiChoiceOptions is initialized as an empty array if null

    const options =
    currentQuestion.MultiChoiceOptions || [];
    if (options.length < 4) {
      const nextPos = options.length;
      const option = fetchNewQuestionOption(nextPos);
      

      option.OptionText = currentOption.OptionText;
      option.ResponseText = currentOption.ResponseText;
      if (selectedAllOptions) {
        option.IsAnswer = true;
      }

      // Add the new option
      const updatedOptions = [...options, option];
      currentQuestion.MultiChoiceOptions = [
        ...options,
        option,
      ];
      
      if (updatedOptions.length === 4) {
        setDisableAddOptions(true);
      }

      // Update currentQuestion with the new options
      setCurrentQuestion({
        ...currentQuestion,
        MultiChoiceOptions: updatedOptions,
      });

      // Clear out option text and option response
      const newOption = fetchNewQuestionOption();
      setCurrentOption(newOption);
    }
  };

  const handleAllOptionsSelectedChange = () => {
    setSelectedAllOptions(!selectedAllOptions);

    const allSelected = !selectedAllOptions;
    const question = currentQuestion;

    if (allSelected) {
      question.MultiChoiceOptions.forEach((option) => {
        option.IsAnswer = true;
      });

      setCurrentQuestion(question);
    } else {
      question.MultiChoiceOptions.forEach((option) => {
        option.Id === selectedIndex
          ? (option.IsAnswer = true)
          : (option.IsAnswer = false);
      });
    }
  };

  const handleActivityChange = (eventTarget) => {
    setActivityChanged(true);
    if (eventTarget.value === 2) {
      let current = {
        id: currentActivity.MultiChoiceQuestion.QuestionId,
        answerPosition: currentActivity.MultiChoiceQuestion.AnswerPosition,
      };
      setCurrentQuestion({
        ...current,
        Options: currentActivity.MultiChoiceQuestion.MultiChoiceOptions,
      });
    }

    setCurrentActivity({
      ...currentActivity,
      ActivityType: eventTarget.value,
    });
  };

  const handleOpenAnswerToggled = () => {
    setAllowOpenAnswer(!allowOpenAnswer);
    setCurrentActivity({ ...currentActivity, Answer: "" });
  };

  const handleValidation = (waypoint) => {
    let formHasError = false;

    setLocationFormError(false);
    setDetailFormError(false);
    setActivityFormError(false);

    for (let key in waypoint) {
      switch (key) {
        case FORM_FIELDS.SHORTNAME:
          if (waypoint[`${FORM_FIELDS.SHORTNAME}`] === "") {
            formHasError = true;

            setShortNameError(true);
            setDetailFormError(true);
          } else {
            setShortNameError(false);
          }
          break;
        case "Radius":
          if (waypoint["Radius"] === "" || Number.isNaN(waypoint["Radius"])) {
            formHasError = true;

            setRadiusError({ show: true, msg: "Cannot be blank" });
            setLocationFormError(true);
          } else {
            setRadiusError({ show: false, msg: "" });
          }
          break;
        case "Latitude":
          if (
            waypoint["Latitude"] === "" ||
            waypoint["Latitude"] === 0 ||
            waypoint["Latitude"] < -90 ||
            waypoint["Latitude"] > 90
          ) {
            formHasError = true;

            setLatitudeError({
              show: true,
              msg: "Invalid waypoint location for zone. Latitude must be between -90 and 90.",
            });
            setLocationFormError(true);
          } else {
            setLatitudeError({ show: false, msg: "" });
          }
          break;
        case "Longitude":
          if (
            waypoint["Longitude"] === "" ||
            waypoint["Longitude"] === 0 ||
            waypoint["Longitude"] < -180 ||
            waypoint["Longitude"] > 180
          ) {
            formHasError = true;

            setLongitudeError({
              show: true,
              msg: "Invalid waypoint location for zone. Longitude must be between -180 and 180.",
            });
            setLocationFormError(true);
          } else {
            setLongitudeError({ show: false, msg: "" });
          }
          break;
        default:
          break;
      }
    }

    return formHasError;
  };

  const clearAllErrors = () => {
    setShortNameError(false);
    setRadiusError({ show: false, msg: "" });
    setLatitudeError({ show: false, msg: "" });
    setLongitudeError({ show: false, msg: "" });
    setProximityError({ show: false, msg: "" });
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleColorPickerClick = (event) => {
    setColorPickerEl(event.currentTarget);
  };

  const handleColorPickerClose = () => {
    setColorPickerEl(null);
  };

  const openColorPicker = Boolean(colorPickerEl);

  const handleFormUpdate = (
    { name, value },
    activity = false,
    option = false
  ) => {
    if (name === FORM_FIELDS.LATITUDE) {
      if (value === "") {
        setCurrentWaypointPosition({
          ...currentWaypointPosition,
          [name]: 0,
        });
      } else {
        if (value >= -90 && value <= 90) {
          setLatitudeError({ show: false, msg: "" });
          setCurrentWaypointPosition({
            ...currentWaypointPosition,
            [name]: value,
          });
        } else {
          setLatitudeError({
            show: true,
            msg: ERROR_MSG.LATITUDE_VALIDATION,
          });
        }
      }
    } else if (name === "Longitude") {
      if (value === "") {
        setLongitudeError({ show: true, msg: "Value cannot be blank" });
      } else if (value >= -180 && value <= 180) {
        setLongitudeError({ show: false, msg: "" });
        setCurrentWaypointPosition({
          ...currentWaypointPosition,
          [name]: value,
        });
      } else {
        setLongitudeError({
          show: true,
          msg: "A valid longitude is between -180 and 180",
        });
      }
    } else if (activity) {
      setCurrentActivity({ ...currentActivity, [name]: value });
    } else if (option) {
      setCurrentOption({ ...currentOption, [name]: value });
    } else {
      setCurrentWaypoint({ ...currentWaypoint, [name]: value });
    }
  };

  const handleColorUpdate = (value) => {
    setCurrentWaypoint({ ...currentWaypoint, Color: value });
  };

  const handleMapClick = (data) => {
    if (data) {
      setCurrentWaypointPosition({
        Latitude: data.lat,
        Longitude: data.lng,
      });
      setCurrentWaypoint({
        ...currentWaypoint,
        Latitude: data.lat,
        Longitude: data.lng,
      });
    }
  };

  const handleSetCordsClick = (lat, lng) => {
    setCurrentWaypointPosition({
      Latitude: parseFloat(lat),
      Longitude: parseFloat(lng),
    });
    setCurrentWaypoint({
      ...currentWaypoint,
      Latitude: parseFloat(lat),
      Longitude: parseFloat(lng),
    });
  };

  const handleSave = () => {
    setSaving(true);

    const waypoint = { ...currentWaypoint };

    waypoint.Radius = currentRadius;
    waypoint.Proximity = currentProximity;
    waypoint.Latitude = currentWaypointPosition.Latitude;
    waypoint.Longitude = currentWaypointPosition.Longitude;

    const activity = { ...currentActivity };
    const question = { ...currentQuestion };

    if (activity.ActivityType === 2) {
      //MultiChoiceQuestion.MultiChoiceOptions
      activity.Question = question;
      activity.Question.Options = question.MultiChoiceOptions;
      delete activity.Question.MultiChoiceOptions;
    } else {
      activity.Question = null;
    }
    waypoint.Activity = activity;

    const errors = handleValidation(waypoint);
    if (!errors) {
      if (isNewWaypoint) {
        addPoint(
          authContext,
          waypoint,
          authContext.tbProps.selectedPark.ParkId,
          waypoint.ZoneId
        ).then((_data) => {
          setSaving(false);
          navigate(URLS.EXPLORER);
        });
      } else {
        updatePoint(
          authContext,
          waypoint,
          authContext.tbProps.selectedPark.ParkId,
          waypoint.ZoneId
        )
          .then((_msg) => {
            setSaving(false);
            setSnackSeverity("success");
            setSnackMessage("Saved!");
            setOpenSnack(true);
            setSaving(false);

            navigate(URLS.EXPLORER);
          })
          .catch((error) => {
            setSnackSeverity("error");
            setSnackMessage("An Error Occurred");
            setOpenSnack(true);
            setSaving(false);
          });
      }
    } else {
      setSaving(false);
    }
  };

  const handleConfirmDialogOpen = () => {
    setShowConfirmation(true);
  };

  const handleConfirmDialogClose = () => {
    setShowConfirmation(false);
  };

  const handleDelete = () => {
    const payload = {
      ZoneId: zoneId,
      Id: currentWaypoint.PointId,
    };
    deletePoint(
      authContext,
      payload,
      authContext.tbProps.selectedPark.ParkId
    ).then((_data) => {
      setShowConfirmation(false);
      navigate("/explorer-quest");
    });
  };

  const handleRadiusChanged = (radius, proximity) => {
    radius = parseInt(radius);
    proximity = parseInt(proximity);

    let _prox = proximity;

    if (radius < 1) {
      setRadiusError({ show: true, msg: "Radius cannot be less than 1" });
    } else if (isNaN(radius)) {
      setRadiusError({ show: true, msg: "Radius cannot be blank" });
    } else {
      setRadiusError({ show: false, msg: "" });
      if (proximity > radius + 10) {
        setCurrentWaypoint({
          ...currentWaypoint,
          Radius: radius,
          Proximity: radius + 10,
        });
        _prox = radius + 10;
      } else if (proximity > radius) {
        setCurrentWaypoint({ ...currentWaypoint, Radius: radius });
      } else {
        setCurrentWaypoint({
          ...currentWaypoint,
          Radius: radius,
          Proximity: radius,
        });
      }
    }

    setCurrentRadius(parseInt(radius));
    setCurrentProximity(parseInt(_prox));
  };

  const handleProximityChanged = (proximity, radius) => {
    radius = parseInt(radius);
    proximity = parseInt(proximity);

    if (proximity < 1) {
      setProximityError({ show: true, msg: "Cannot be less than 1" });
    } else {
      setProximityError({ show: false, msg: "" });
      if (proximity >= radius && proximity <= radius + 10) {
        setCurrentWaypoint({ ...currentWaypoint, Proximity: proximity });
        setCurrentProximity(proximity);
      }
    }
  };

  async function handleImageConvert(event) {
    const imageFile = event.target.files[0];

    const options = {
      maxSize: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(imageFile, options);

      await imageCompression
        .getDataUrlFromFile(compressedFile)
        .then((resolve, reject) => {
          if (!!!reject) {
            setCurrentWaypoint({ ...currentWaypoint, Image: resolve });
          }
        });
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    setLoading(true);
    clearAllErrors();

    let parkId;
    if (authContext.tbProps.selectedPark) {
      if (authContext.tbProps.selectedPark === null) {
        parkId = cookies("park");
      } else {
        parkId = authContext.tbProps.selectedPark.ParkId;
      }
      const zoneId = parseInt(props.zoneId);
      const wpId = parseInt(props.wpId);

      getZone(authContext, parkId, zoneId).then((data) => {
        setZone(data);

        getPoints(authContext, { ParkId: parkId, ZoneId: zoneId }).then(
          (_data) => {
            setAllWaypoints(_data.Points);

            if (path.includes("waypoint/new")) {
              const waypoint = fetchNewPoint();
              waypoint.ZoneId = zoneId;

              setCurrentWaypoint(waypoint);
              setCurrentWaypointPosition({
                Latitude: waypoint.Latitude,
                Longitude: waypoint.Longitude,
              });
              setCurrentActivity(waypoint.Activity);
              setCurrentRadius(waypoint.Radius);
              setCurrentProximity(waypoint.Proximity);
              setCurrentQuestion(waypoint.Activity.MultiChoiceQuestion);

              setIsNewWaypoint(true);
              setLoading(false);
            } else {
              getPoint(authContext, parkId, zoneId, wpId).then((data) => {
                setCurrentWaypoint(data);
                setCurrentWaypointPosition({
                  Latitude: data.Latitude,
                  Longitude: data.Longitude,
                });
                setCurrentActivity(data.Activity);
                setCurrentRadius(data.Radius);
                setCurrentProximity(data.Proximity);

                const newQuestion = fetchNewQuestion();
                const newOption = fetchNewQuestionOption();

                if (data.Activity.ActivityType === 3) {
                  if (data.Activity.Answer || data.Activity.Answer === "") {
                    setAllowOpenAnswer(true);
                  }
                }

                if (data.Activity.Question === null) {
                  setCurrentQuestion(newQuestion);
                  setCurrentOption(newOption);
                  setDisableAddOptions(false);
                } else {
                  if (data.Activity?.MultiChoiceQuestion) {
                    data.Activity.MultiChoiceQuestion.MultiChoiceOptions =
                      data.Activity.MultiChoiceQuestion.MultiChoiceOptions ||
                      [];
                    data.Activity.MultiChoiceQuestion.MultiChoiceOptions.forEach(
                      (option) => {
                        if (
                          option.Position ===
                          data.Activity.MultiChoiceQuestion.AnswerPosition
                        ) {
                          option.IsAnswer = true;
                        } else {
                          option.IsAnswer = false;
                        }
                      }
                    );
                  }

                  setCurrentQuestion(data.Activity.MultiChoiceQuestion);
                  if (data.Activity?.MultiChoiceQuestion) {
                    data.Activity.MultiChoiceQuestion.MultiChoiceOptions =
                      data.Activity.MultiChoiceQuestion.MultiChoiceOptions ||
                      [];
                    if (
                      data.Activity.MultiChoiceQuestion.MultiChoiceOptions
                        .length < 4
                    ) {
                      setCurrentOption(newOption);
                      setDisableAddOptions(false);
                    } else {
                      setDisableAddOptions(true);
                    }
                  }
                }

                if (data.ImageId) {
                  getImage(authContext, data.ImageId, ImageType.Scavenger).then(
                    (response) => {
                      setCurrentWaypoint({
                        ...data,
                        Image: response.data.Image,
                      });
                    }
                  );
                }

                setIsNewWaypoint(false);
                setLoading(false);
              });
            }
          }
        );
      });
    }
    // TODO get all points
  }, [authContext.tbProps.selectedPark]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {}, [currentActivity]);

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {/* TAB HEADER */}
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Tabs
              value={tabValue}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabChange}
              variant="fullWidth"
              aria-label="waypoint tab options"
            >
              <Tab
                label="Location"
                icon={locationFormError ? <ErrorIcon /> : null}
                {...A11yProps(0)}
              />
              <Tab
                label="Details"
                icon={detailFormError ? <ErrorIcon /> : null}
                {...A11yProps(1)}
              />
              <Tab
                label="Activity"
                icon={activityFormError ? <ErrorIcon /> : null}
                {...A11yProps(2)}
              />
            </Tabs>
          </Paper>
        </Grid>

        <Grid container item spacing={2} style={{ height: "80vh" }}>
          <Grid item xs={12} md={6}>
            <Paper className={classes.paper}>
              {/* LOCATION INFORMATION */}
              <TabPanel value={tabValue} index={0}>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <>
                    <WaypointLocationForm
                      currentWaypoint={currentWaypoint}
                      belongsToHunt={zone}
                      handleChange={handleFormUpdate}
                      handleUpdateCords={handleSetCordsClick}
                      updateRadius={handleRadiusChanged}
                      latitudeError={latitudeError}
                      longitudeError={longitudeError}
                      radiusError={radiusError}
                      handleColorPickerClick={handleColorPickerClick}
                      handleColorPickerClose={handleColorPickerClose}
                      handleColorUpdate={handleColorUpdate}
                      openColorPicker={openColorPicker}
                      colorPickerEl={colorPickerEl}
                      setLatitudeError={setLatitudeError}
                      setLongitudeError={setLongitudeError}
                    />
                  </>
                )}
              </TabPanel>

              {/* DETAILS */}
              <TabPanel value={tabValue} index={1}>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <WaypointDetailsForm
                    currentWaypoint={currentWaypoint}
                    handleChange={handleFormUpdate}
                    shortNameError={shortNameError}
                  />
                )}
              </TabPanel>

              {/* ACTIVITY SETTINGS */}
              <TabPanel value={tabValue} index={2}>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <>
                    <Grid2 container spacing={3} mt={2} >
                      <FormGrid size={{ xs: 12 }}>
                        <FormSelect
                          selectLabel="waypoint-activity-type"
                          selectName="Waypoint Activity"
                          activity={currentActivity}
                          handleChange={handleActivityChange}
                          items={[
                            { value: 1, name: "Confirmation" },
                            { value: 2, name: "Multiple Choice" },
                            { value: 3, name: "Fill in the Blank" },
                          ]}
                        />
                      </FormGrid>
                      <FormGrid size={{ xs: 12 }}>
                        <ActivityDescriptions
                          activityType={currentActivity.ActivityType}
                        />
                      </FormGrid>
                    </Grid2>

                    {/* GENERAL */}
                    {/* RENDER FOR ALL FORMS */}
                    <WaypointActivityForm
                      currentActivity={currentActivity}
                      currentWaypointImage={currentWaypoint.Image}
                      handleChange={handleFormUpdate}
                      handleImageUpload={handleImageConvert}
                    />

                    {/* CONFIRMATION */}
                    {currentActivity.ActivityType === 1 ? (
                      <ConfirmationActivityForm
                        activity={currentActivity}
                        handleChange={handleFormUpdate}
                      />
                    ) : null}

                    {/* MULTIPLE CHOICE */}
                    {currentActivity.ActivityType === 2 ? (
                      <MultiChoiceActivityForm
                        option={currentOption}
                        activityQuestion={currentQuestion}
                        handleChange={handleFormUpdate}
                        disableAdd={disableAddOptions}
                        handleAddOption={handleOptionAdd}
                        allOptionsCorrect={selectedAllOptions}
                        handleAllOptionsCorrectToggle={
                          handleAllOptionsSelectedChange
                        }
                        selectedIndex={selectedIndex}
                        handleCorrectOptionSelected={handleListItemClick}
                        editingOption={editingOption}
                        handleOptionInputChange={handleOptionInputUpdate}
                        handleOptionUpdate={handleOptionUpdate}
                        handleOptionEdit={handleOptionEdit}
                        handleOptionCancelUpdate={handleOptionCancelUpdate}
                        handleOptionDelete={handleOptionDelete}
                      />
                    ) : null}

                    {/* FILL IN THE BLANK */}
                    {currentActivity.ActivityType === 3 ? (
                      <OpenTextActivityForm
                        activity={currentActivity}
                        handleChange={handleFormUpdate}
                        allowAnyText={allowOpenAnswer}
                        allowAnyTextToggled={handleOpenAnswerToggled}
                      />
                    ) : null}
                  </>
                )}
              </TabPanel>

              {/* ACTIONS */}
              <Paper
                className={classes.paper}
                style={{ textAlign: "right", boxShadow: "none" }}
              >
                {isNewWaypoint ? null : (
                  <Button
                    size="small"
                    disabled={loading || saving}
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={handleConfirmDialogOpen}
                  >
                    Delete Waypoint
                  </Button>
                )}
                <Button
                  size="small"
                  disabled={loading || saving}
                  color="primary"
                  startIcon={<SaveIcon />}
                  onClick={handleSave}
                >
                  Save Waypoint
                </Button>
              </Paper>
            </Paper>
          </Grid>

          {/* TAB PANELS */}
          <Grid item xs={12} md={6}>
            <Paper
              className={classes.paper}
              style={{ padding: "0", height: "100%", borderRadius: "10px" }}
            >
              {/* LOCATION MAP */}
              <MapPanel value={tabValue} index={0} style={{ height: "100%" }}>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <div className="modal-map-row" style={{ height: "100%" }}>
                    <WaypointMap
                      mapClickCallback={handleMapClick}
                      currentLocation={currentWaypoint}
                      lat={parseFloat(currentWaypointPosition.Latitude)}
                      lng={parseFloat(currentWaypointPosition.Longitude)}
                      radius={parseInt(currentRadius)}
                      proximity={parseInt(currentProximity)}
                      color={currentWaypoint.Color}
                      allWaypoints={allWaypoints}
                      belongsToZone={zone}
                      isScavengerPoint={true}
                      showClearBtn={false}
                    />
                  </div>
                )}
              </MapPanel>

              {/* DETAIL WIRE FRAMES */}
              <TabPanel value={tabValue} index={1}>
                <DetailPreview waypoint={currentWaypoint} />
              </TabPanel>

              {/* ACTIVITY WIRE FRAMES */}
              <TabPanel value={tabValue} index={2}>
                {currentActivity.ActivityType === 1 ? (
                  <ConfirmationPreview
                    currentActivity={currentActivity}
                    activityImage={currentWaypoint.Image}
                  />
                ) : null}
                {currentActivity.ActivityType === 2 ? (
                  <MultiChoicePreview
                    currentActivity={currentActivity}
                    activityImage={currentWaypoint.Image}
                    currentQuestion={currentQuestion}
                  />
                ) : null}
                {currentActivity.ActivityType === 3 ? (
                  <TextEntryPreview
                    currentActivity={currentActivity}
                    activityImage={currentWaypoint.Image}
                  />
                ) : null}
              </TabPanel>
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      <ConfirmationDialog
        open={showConfirmation}
        close={handleConfirmDialogClose}
        confirm={handleDelete}
        warning={false}
      />

      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert onClose={handleClose} severity={snackSeverity}>
          {snackMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

WaypointCMS.propTypes = {
  zoneId: PropTypes.string,
  wpId: PropTypes.string,
};

export default WaypointCMS;
