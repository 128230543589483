import axios from 'axios';
import { getBaseUrl, getAPIVersion, getSecretKey } from 'config';

const version = getAPIVersion();
const sk = getSecretKey();
const path = `/api/Parks/`;

axios.defaults.headers.post['Content-Type'] = 'application/json';

const ENDPOINTS = {
  FETCH: {
    PARKS: 'getparks',
    FAQS: 'getfaqs',
    BORDER: 'getparkborder',
    REWARDS: 'getrewardtiers',
    HUNT: 'getscavengerhunt',
    TUTORIAL: 'gettutorial',
  },
  UPDATE: {
    PARK: 'updatepark',
    FAQS: 'updatefaqs',
    REWARDS: 'updaterewardtiers',
    TUTORIAL: 'updatetutorial',
  },
};

// TODO future remove.
export function getParks(orgId, context, callback) {
  fetch(getBaseUrl() + path + ENDPOINTS.FETCH.PARKS, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + context.token,
    },
    body: JSON.stringify({
      orgId: orgId,
      s: sk,
    }),
  })
    .then(res => {
      if (res.status === 401) {
        context.logout();
      } else {
        return res.json();
      }
    })
    .then(data => {
      callback(data);
    })
    .catch(console.log);
}

export async function fetchParksForOrg(context, orgId) {
  const response = await fetch(getBaseUrl() + path + ENDPOINTS.FETCH.PARKS, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + context.token,
    },
    body: JSON.stringify({
      orgId: orgId,
      s: sk,
    }),
  });

  if (response.ok) {
    try {
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
}
//TODO
// export function fetchParksForOrg(context, orgId) {
//   return axios({
//     method: 'post',
//     url: getBaseUrl() + path + ENDPOINTS.FETCH.PARKS,
//     headers: { Authorization: `Bearer ${context.token}` },
//     data: {
//       orgId,
//       s: sk,
//     },
//   });
// }

export function getParkBoundary(context, parkId) {
  return axios({
    method: 'post',
    url: getBaseUrl() + path + ENDPOINTS.FETCH.BORDER,
    headers: { Authorization: `Bearer ${context.token}` },
    data: {
      parkId: parkId,
    },
  });
}

export async function updatePark(context, orgId, park) {
  return axios({
    method: 'post',
    url: getBaseUrl() + path + 'updatepark',
    headers: { Authorization: `Bearer ${context.token}` },
    data: {
      orgId: orgId,
      id: park.ParkId,
      name: park.Name,
      address: park.Address,
      city: park.City,
      state: park.State,
      zip: park.Zip,
      latitude: park.Latitude,
      longitude: park.Longitude,
      borderWkt: "",
      zoom: park.Zoom,
      identityText: ""
    }
    ,
  });
}

export async function deletePark(context, park) {
  return axios({
    method: 'post',
    url: getBaseUrl() + path + 'deletepark',
    headers: { Authorization: `Bearer ${context.token}` },
    data: {
      parkId: park.ParkId,
      huntId: 0,
      inactiveVisible: true
    }
    ,
  });
}


export async function addPark(context, orgId, park) {
  return axios({
    method: 'post',
    url: getBaseUrl() + path + 'addpark',
    headers: { Authorization: `Bearer ${context.token}` },
    data: {
      orgId: orgId,
      id: park.ParkId,
      name: park.Name,
      address: park.Address,
      city: park.City,
      state: park.State,
      zip: park.Zip,
      latitude: park.Latitude,
      longitude: park.Longitude,
      borderWkt: "",
      zoom: park.Zoom,
      identityText: "string"
    }
    ,
  });
}

export async function getTutorial(context, parkId) {
  return axios({
    method: 'post',
    url: getBaseUrl() + path + ENDPOINTS.FETCH.TUTORIAL,
    headers: { Authorization: `Bearer ${context.token}` },
    data: {
      parkId,
    },
  });
}

export async function updateTutorial(context, parkId, tutorial) {
  return axios({
    method: 'post',
    url: getBaseUrl() + path + ENDPOINTS.UPDATE.TUTORIAL,
    headers: { Authorization: `Bearer ${context.token}` },
    data: {
      parkId: parkId,
      id: tutorial.Id,
      scavengerHuntName: tutorial.ScavengerHuntName,
      welcomeText1: tutorial.WelcomeText1,
      welcomeText2: tutorial.WelcomeText2,
      welcomeText3: tutorial.WelcomeText3,
      stateText1: tutorial.StateText1,
      stateText2: tutorial.StateText2,
      stateText3: tutorial.StateText3,
      description1: tutorial.Description1,
      description2: tutorial.Description2,
      description3: tutorial.Description3,
      title1: tutorial.Title1,
      title2: tutorial.Title2,
      title3: tutorial.Title3,
    },
  });
}

export async function getScavengerHunt(context, parkId) {
  return axios({
    method: 'post',
    url: getBaseUrl() + path + 'getscavengerhunt',
    headers: { Authorization: `Bearer ${context.token}` },
    data: {
      parkId,
    },
  });
}

export async function getRewardTiers(context, parkId) {
  return axios({
    method: 'post',
    url: getBaseUrl() + path + ENDPOINTS.FETCH.REWARDS,
    headers: { Authorization: `Bearer ${context.token}` },
    data: {
      parkId,
    },
  });
}

export async function updateRewardTiers(context, parkId, rewardTiers) {
  return axios({
    method: 'post',
    url: getBaseUrl() + path + 'updaterewardtiers',
    headers: { Authorization: `Bearer ${context.token}` },
    data: {
      parkId: parkId,
      rewardTiers: rewardTiers,
    },
  });
}

export async function getFaqs(context) {
  const response = await fetch(
    getBaseUrl() + path + ENDPOINTS.UPDATE.TUTORIAL,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + context.token,
      },
      body: JSON.stringify({
        s: sk,
      }),
    }
  );

  if (response.ok) {
    try {
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
}

export async function updateFaqs(context) {
  const response = await fetch(
    getBaseUrl() + path + ENDPOINTS.UPDATE.TUTORIAL,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + context.token,
      },
      body: JSON.stringify({
        s: sk,
      }),
    }
  );

  if (response.ok) {
    try {
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
}
