import { useState, useEffect, useContext } from 'react';
import AuthContext from 'context/Auth/auth';
import { UserContext } from 'context/UserContext';
import { IOrganization } from 'models/IOrganization';
import { getOrg } from 'services/OrgsService';
import { IPark } from 'models/IPark';
import { fetchParksForOrg } from 'services/Parks.Service';
import { makeStyles } from '@mui/styles';
import {
  Card,
  Typography,
  CircularProgress,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ParkIcon from '@mui/icons-material/Park';
import { alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { grey } from '@mui/material/colors';
import Tooltip from "@mui/material/Tooltip";
import ParkMap from "../pages/ExplorerQuest/cms/maps/ParkMap";
import ParkMenu from "../common/menus/ParkMenu";
import ParkDrawer from "../common/drawers/ParkDrawer";
import CustomSelect from '../common/select/CustomSelect';
import { useCookies } from "react-cookie";
import { getOrganizations } from "services/OrgsService";
import Organization from "models/Organization";
import { useSelector, useDispatch } from 'react-redux';
import { setOrg } from '../../slices/organization-slice';


const Div = styled('div')(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
  textTransform: 'none',
}));

const DivTitle = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  textTransform: 'none',
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const useStyles = makeStyles({
  root: {
    width: 350,
    height: 450
    //margin: 2,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});


export default function Overview() {
  const classes = useStyles();
  const authContext = useContext(AuthContext);
  const { state } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [organization, setOrganization] = useState<IOrganization | undefined>(
    undefined
  );
  const [organizations, setOrganizations] = useState([]);

  const [cookies] = useCookies(["token"]);
  const parkDefault = {
    Id: 0,
    ParkId: 0,
    Name: "",
    Address: "",
    City: "",
    State: "",
    Zip: "",
    Latitude: 36.5157758,
    Longitude: -118.5442091,
    Zoom: 5
  }

  const [currentPark, setCurrentSelectedPark] = useState<IPark>(
    parkDefault
  );
  const [accessParks, setAccessParks] = useState<IPark[]>();
  const [expanded, setExpanded] = useState(false);  
  const [open, setOpen] = useState(false);   
  const dispatch = useDispatch();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleSetSelectedPark = (selectedPark: IPark) => {
    setCurrentSelectedPark(selectedPark);
    setOpen(true);
  };

  const handleParkDialog = (value: boolean) => {
    setOpen(value);
  };

  useEffect(() => {
    setLoading(true);
    const orgId = authContext?.tbProps?.selectedOrg?.OrgId ?? state.orgId;
      if (orgId) {
        handleChangeOrg(authContext?.tbProps?.selectedOrg);
      }  
  }, [state, authContext.tbProps]);

  const handleChangeOrg = (org: Organization) => {
    if(org){
    setOrganization(org);
    dispatch(setOrg(org.Id))
    setLoading(true);
    fetchParksForOrg(authContext, org.Id)
      .then(parks => {
        if (parks.length > 0) {
          const listOfParks: IPark[] = [];
          parks.forEach((park: IPark) => {
            //if (state.accessToParks.includes(park.ParkId)) {
            listOfParks.push(park);
            //}
          });
          setAccessParks(listOfParks);
        } else {
          setAccessParks([]);
        }
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
    }
  };


  useEffect(() => {
    setLoading(true);

    if (state.orgId !== null) {
      getOrg(authContext, state.orgId)
        .then(response => {
          setOrganization(response.data);
          fetchParksForOrg(authContext, state.orgId)
            .then(parks => {
              if (state.accessToParks.length > 0) {
                const listOfParks: IPark[] = [];
                parks.forEach((park: IPark) => {
                  //if (state.accessToParks.includes(park.ParkId)) {
                  listOfParks.push(park);
                  //}
                });
                setAccessParks(listOfParks);
              } else {
                setAccessParks([]);
              }
            })
            .catch(error => {
              console.error(error);
            })
            .finally(() => {
              setLoading(false);
            });
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (cookies.token) {
      getOrganizations(cookies).then((data) => {
        if (typeof data === "undefined") {
          return;
        } else {
          const organizationList = data.map((org: Organization) => {
            return new Organization(
              org.OrgId,
              org.Name,
              org.Address,
              org.City,
              org.State,
              org.Zip,
              org.ContactEmail,
              org.ContactPhoneNumber
            );
          });
          setOrganizations(organizationList);
        }
      });
    }
  }, [cookies, organization]);
  

  const [searchQuery, setSearchQuery] = useState('');
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleDelete = (selectedPark: IPark) => {
    const filteredParks = accessParks?.filter(park => park.ParkId !== selectedPark.ParkId);
    setAccessParks(filteredParks);
    setOpen(false);
  };

  const handleSave = (selectedPark: IPark, updatedDataPark: IPark) => {

    setAccessParks((prevParks) => {
      if (selectedPark.ParkId === 0) {
        return [...(prevParks || []), updatedDataPark];
      }
      return prevParks?.map((park) =>
        park.ParkId === selectedPark.ParkId ? { ...park, ...updatedDataPark } : park
      );
    });
    setOpen(false);
  };

  const filteredParks = accessParks?.filter((park: IPark) =>
    park.Name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    park.City.toLowerCase().includes(searchQuery.toLowerCase()) ||
    park.State.toLowerCase().includes(searchQuery.toLowerCase()) ||
    park.Zip.toLowerCase().includes(searchQuery.toLowerCase()) ||
    park.Address.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      {typeof organization !== undefined ? (
        <Box sx={{
          minWidth: 120, width: 300, display: 'flex',
          mt: 'calc(var(--template-frame-height, 0px) + 4px)',
          pb: 4
        }}>
          {organizations?.length > 0 && organization && (
            <CustomSelect
              options={organizations}
              selectedOption={organization}
              onChangeOption={handleChangeOrg}
            />
          )}
        </Box>
      ) : null}
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {accessParks?.length == 0 ? (
            <Div>{"Currently, this organization has no parks to display."}</Div>
          ) : (
            <Paper sx={{ width: 'auto', margin: 'auto', overflow: 'hidden' }}>
              <Box sx={{ flexGrow: 1 }}>
                <AppBar
                  position="static"
                  color="default"
                  elevation={0}
                  sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
                >
                  <Toolbar variant="regular">
                    <Typography
                      variant="h6"
                      noWrap
                      component="div"
                      sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                    >
                      Parks
                    </Typography>
                    <Search>
                      <SearchIconWrapper>
                        <SearchIcon />
                      </SearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ 'aria-label': 'search' }}
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                    </Search>
                    <IconButton
                      size="large"
                      edge="start"
                      color="inherit"
                      onClick={() => handleSetSelectedPark(parkDefault)}
                    >
                      <Tooltip title="Add Park">
                        <AddCircleIcon />
                      </Tooltip>
                    </IconButton>
                  </Toolbar>
                </AppBar>
              </Box>
              <Grid container spacing={2} sx={{ flexGrow: 1, p: 2 }}>
                {accessParks !== undefined &&
                  filteredParks?.map((park: IPark) => {
                    return (
                      <Grid key={park.ParkId} justifyContent="center" alignItems="center">
                        <Tooltip title={park.Name} placement="right-start" arrow>
                        <Card className={classes.root} style={{ margin: 4 }}>
                          <CardHeader
                            avatar={
                              <Avatar sx={{ bgcolor: grey[500], width: 24, height: 24 }} aria-label="recipe">
                                <ParkIcon />
                              </Avatar>
                            }
                            action={
                              <ParkMenu park={park} onEdit={handleSetSelectedPark} onDelete={handleDelete} authContext={authContext} />
                            }
                            title={park.Name}
                            subheader={`${park.Address} ${park.City}, ${park.State} ${park.Zip}`}
                            titleTypographyProps={{ textOverflow: 'elipsis', noWrap: true , width: '220px'}}
                            subheaderTypographyProps={{ textOverflow: 'elipsis', noWrap: true , width: '220px'}}
                          />
                          <ParkMap
                            clickCallback={handleExpandClick}
                            disbledMarkerMove={true}
                            mapCenter={{ lat: park.Latitude, lng: park.Longitude }}
                            lat={park.Latitude}
                            lng={park.Longitude}
                            radius={0}
                            color={`#fff`} existingLocations={[]} currentMarker={{ Latitude: park.Latitude, Longitude: park.Longitude, ZoneId: park.ParkId, Zoom: park.Zoom }} />
                        </Card>
                        </Tooltip>
                      </Grid>
                    );
                  })}
                {filteredParks?.length == 0 && (
                  <Div>{"No parks found matching the provided name or address."}</Div>
                )}
              </Grid>
            </Paper>)}
          <ParkDrawer
            organization={organization}
            park={currentPark}            
            open={open}
            onParkDialog={handleParkDialog} onDelete={handleDelete} onSave={handleSave} />
        </>
      )}
    </>
  );
}
