import { IActivity } from 'models/IActivity';

import { makeStyles } from '@mui/styles'
//import Grid from '@mui/material/Grid';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';

// Paths to Default Images must be absolute.
import activityBg from '../../../../../images/cms/activity/activity.png';
import defaultImg from '../../../../../images/cms/activity/no-image-default.png';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    background: `url(${activityBg}) no-repeat center`,
    backgroundSize: 'contain',
    height: '700px',

    '& > *': {
      boxSizing: 'content-box',
      width: '320px',
    },
  },
  pointImage: {
    display: 'grid',
    maxWidth: '320px',
    marginTop: '74px',
  },
  image: {
    justifySelf: 'center',
    maxHeight: '155px',
    maxWidth: '320px',
    borderRadius: '10px',
  },
  header: {
    textAlign: 'left',

    '& > h3': {
      color: '#000',
      padding: '0 10px',
    },
  },
  question: {
    textAlign: 'left',

    '& > p': {
      color: '#000',
      padding: '0 10px',
    },
  },
  text: {
    textAlign: 'center',

    '& > textarea': {
      border: '2px solid',
      ////borderColor: theme.palette.primary.main,
      borderRadius: '10px',
      color: '#000',
      padding: '0 10px',
      height: '100px',
      width: '285px',
    },
  },
  answers: {
    flexGrow: 2,
    marginBottom: '112px',
    display: 'grid',

    '& > div': {
      alignSelf: 'center',
      border: '2px solid',
      //: theme.palette.primary.main,
      borderRadius: '10px',
      ////color: theme.palette.primary.main,
      fontWeight: 700,
      margin: '0px 6px',
      padding: '8px 0',
    },
  },
}));

interface IProps {
  currentActivity: IActivity;
  activityImage: string | undefined;
}
function TextEntryPreview(props: IProps) {
  const classes = useStyles();

  return (
    <>
      <Box sx={{ flexGrow: 1 }} className={classes.root} >
        <Grid container alignItems="center"
          justifyContent="center">
          <Grid className={classes.pointImage} size={12}>
            {props.activityImage !== undefined ? (
              <img
                src={props.activityImage}
                alt='Point Imagery'
                className={classes.image}
              />
            ) : (
              <img
                src={defaultImg}
                alt='Default Imagery'
                className={classes.image}
              />
            )}
          </Grid>
          <Grid className={classes.header} size={12}>
            {props.currentActivity.HeaderText !== '' ? (
              <h3>{props.currentActivity.HeaderText}</h3>
            ) : (
              <h3>
                <em>Header Text Placeholder</em>
              </h3>
            )}
          </Grid>
          <Grid className={classes.question} size={12}>
            {props.currentActivity.QuestionText !== '' ? (
              <p>{props.currentActivity.QuestionText}</p>
            ) : (
              <p>
                <em>Question Text Placeholder</em>
              </p>
            )}
          </Grid>
          <Grid className={classes.text} size={12}>
            <textarea
              placeholder={
                props.currentActivity.PlaceholderText !== '' &&
                  props.currentActivity.PlaceholderText !== null
                  ? props.currentActivity.PlaceholderText
                  : 'Placeholder Text'
              }
              readOnly={true}
              disabled={true}
            ></textarea>
          </Grid>
          <Grid className={classes.answers}>
            <div>Submit Answer</div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default TextEntryPreview;
