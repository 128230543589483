import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ZoneList from '../cms/lists/ZoneList';
import CMSMap from '../cms/maps/CMSMap';

import { makeStyles } from '@mui/styles'
import {
  Button,
  ButtonGroup,
  Grid,
  CircularProgress,
  Paper,
} from '@mui/material';
import { List as ListIcon, GridOn as GridOnIcon } from '@mui/icons-material';;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: '82vh',
    display: 'flex',
    justifyContent: 'center',
  },
}));

function QuestBuilderTab({
  zones,
  addButtonDisabled,
  zoneSelected,
  displayMap,
  cords,
  selected,
  parkBoundary,
}) {
  const classes = useStyles();
  const [layout, setLayout] = useState('list');

  // displayMap = false;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <ButtonGroup
          variant='text'
          color='primary'
          aria-label='text primary button group'
          style={{ float: 'right' }}
        >
          {/*<Button onClick={() => setLayout('list')}>
            <ListIcon />
          </Button>*/}
        </ButtonGroup>

        <ZoneList
          items={zones}
          disableAddButton={addButtonDisabled}
          allLocations={zones}
          onZoneSelected={zoneSelected}
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <Paper className={classes.paper}>
          {!displayMap ? (
            <CircularProgress style={{ alignSelf: 'center' }} />
          ) : (
            <CMSMap
              mapCenter={{
                lat: cords.lat,
                lng: cords.lng,
              }}
              locations={zones}
              selectedLocation={selected}
              parkBoundary={parkBoundary}
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}

QuestBuilderTab.propTypes = {
  zones: PropTypes.array,
  addButtonDisabled: PropTypes.bool,
  zoneSelected: PropTypes.func,
  displayMap: PropTypes.bool,
  cords: PropTypes.object,
  selected: PropTypes.object,
  parkBoundary: PropTypes.array,
};

export default QuestBuilderTab;
