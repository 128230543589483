import axios from 'axios';

import { getBaseUrl, getAPIVersion, getSecretKey } from 'config';

const version = getAPIVersion();
const sk = getSecretKey();
const path = `/api/Auth/`;
const URLS = {
  AUTH: 'authenticate',
  CHECK: 'checkauth',
};

// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
axios.defaults.headers.post['Content-Type'] = 'application/json';

export function authenticate(email, password) {
  return axios({
    method: 'post',
    url: getBaseUrl() + path + URLS.AUTH,
    data: {
      userName: email,
      password: password,
      secretKey: sk,
    },
  });
}
// export async function authenticate(email, password) {
//   const response = await fetch(getBaseUrl() + path + URLS.AUTH, {
//     method: 'POST',
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({
//       userName: email,
//       password: password,
//       secretKey: sk,
//     }),
//   });

//   if (response.ok) {
//     try {
//       return response.json();
//     } catch (error) {
//       console.error(error);
//     }
//   } else {
//     return response.status;
//   }
// }

export async function checkAuth(email, password) {
  const response = await fetch(getBaseUrl() + path + URLS.CHECK, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({}),
  });

  if (response.ok) {
    try {
      return response.json();
    } catch (error) {
      console.error(error);
    }
  } else {
    return response.status;
  }
}
