import { ChangeEvent } from 'react';

import { makeStyles } from '@mui/styles'
import { TextField, Button } from '@mui/material';
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/system';

const FormGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const useStyles = makeStyles(theme => ({
  subContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  image: {
    color: '#2ecc71',
  },
  missingImage: {
    color: '#e74c3c',
  },
}));

interface IProps {
  currentActivity: any;
  currentWaypointImage: any;
  handleChange: (e: EventTarget, b: boolean) => void;
  handleImageUpload: (e: ChangeEvent<HTMLInputElement>) => void;
}
const WaypointActivityForm = (props: IProps) => {
  const classes = useStyles();

  return (
    <Grid container spacing={3} mt={2} >
      <FormGrid size={{ xs: 6 }}>
        <Button variant='contained' color='primary' component='label' style={{width:180}}>
          Select Image
          <input
            type='file'
            accept='.jpeg, .png, .jpg'
            hidden
            onChange={e => props.handleImageUpload(e)}
          />
        </Button>
      </FormGrid>
      <FormGrid size={{ xs: 6 }}>
        {props.currentWaypointImage ? (
          <div>
            <CheckCircleIcon className={classes.image} />
            <span>Current Point has Image</span>
          </div>
        ) : (
          <div>
            <CancelIcon className={classes.missingImage} />
            <span>Current Waypoint is missing an Image</span>
          </div>
        )}
      </FormGrid>
      <FormGrid size={{ xs: 12 }}>
        <TextField
          id='waypoint-headerText'
          label='Header Text'
          name='HeaderText'
          helperText=''
          variant='outlined'
          value={props.currentActivity.HeaderText}
          onChange={e => props.handleChange(e.target, true)}
          fullWidth
        />
      </FormGrid>
      <FormGrid size={{ xs: 12 }}>
        <TextField
          id='waypoint-questionText'
          label='Question Text'
          name='QuestionText'
          helperText=''
          variant='outlined'
          value={props.currentActivity.QuestionText}
          onChange={e => props.handleChange(e.target, true)}
          fullWidth
          multiline
          rows={4}
        />
      </FormGrid>
    </Grid>
  );
};

export default WaypointActivityForm;
