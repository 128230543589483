import * as React from 'react';
import { useEffect } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import { useForm } from "react-hook-form";
import { FormInputText } from "components/form/FormInputText";
import DialogActions from '@mui/material/DialogActions';
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';


interface IFormInput {
    Id: number | null;
    TierName: string;
    TierPriority: number;
    CompletionMessage: string;
    NextStepsMessage: string;
    RequiredChallengesPct: number;
    RequiredPointsPct: number;
    Image: string;
}

interface RewardFormProps {
    currentReward: IFormInput;
    callBackRewardsSave: (arg0: IFormInput) => void;
}


const defaultValues: IFormInput = {
    Id: null,
    TierName: "",
    TierPriority: 0,
    CompletionMessage: "",
    NextStepsMessage: "",
    RequiredChallengesPct: 0,
    RequiredPointsPct: 0,
    Image: ""
};

const RewardForm: React.FC<RewardFormProps> = ({ currentReward, callBackRewardsSave }) => {
    const schema = Yup.object().shape({
        TierName: Yup.string().required("Tier Name is required"),
        TierPriority: Yup.number().required('Tier Priority is required'),
        CompletionMessage: Yup.string().required('Completion Message is required'),
        NextStepsMessage: Yup.string().required('Next Step Message is required'),
        RequiredChallengesPct: Yup.number().when('TierName', {
            is: (value: string) => value === 'Bronze',
            then: (schema) => schema.min(1, 'Bronze completion must be at least 1%')
                .max(40, 'Bronze completion cannot be more than 40%'),
        }).when('TierName', {
            is: (value: string) => value === 'Silver',
            then: (schema) => schema.min(41, 'Silver completion must be at least 41%')
                .max(70, 'Silver completion cannot be more than 70%'),
        }).when('TierName', {
            is: (value: string) => value === 'Gold',
            then: (schema) => schema.min(71, 'Gold completion must be at least 71%')
                .max(100, 'Gold completion cannot be more than 100%'),
        }),
        RequiredPointsPct: Yup.number().when('TierName', {
            is: (value: string) => value === 'Bronze',
            then: (schema) => schema.min(1, 'Bronze completion must be at least 1%')
                .max(40, 'Bronze completion cannot be more than 40%'),
        }).when('TierName', {
            is: (value: string) => value === 'Silver',
            then: (schema) => schema.min(41, 'Silver completion must be at least 41%')
                .max(70, 'Silver completion cannot be more than 70%'),
        }).when('TierName', {
            is: (value: string) => value === 'Gold',
            then: (schema) => schema.min(71, 'Gold completion must be at least 71%')
                .max(100, 'Gold completion cannot be more than 100%'),
        }),
        Image: Yup.string().required('Last name is required'),
    });

    const { handleSubmit, control, setValue, reset, formState: { errors } } = useForm({
        mode: "onBlur",
        resolver: yupResolver(schema),
        defaultValues: defaultValues,
        shouldFocusError: true,
        criteriaMode: "all",
        reValidateMode: "onChange"
    });


    const onSubmit = (data: any) => callSubmit(data);
    const callSubmit = (data: IFormInput) => {
        callBackRewardsSave(data);
    };


    const handleRewardSelected = (): void => {
        if (currentReward) {
            defaultValues.Id = currentReward.Id;
            defaultValues.TierName = currentReward.TierName;
            defaultValues.TierPriority = currentReward.TierPriority;
            defaultValues.CompletionMessage = currentReward.CompletionMessage;
            defaultValues.NextStepsMessage = currentReward.NextStepsMessage;
            defaultValues.RequiredChallengesPct = currentReward.RequiredChallengesPct;
            defaultValues.RequiredPointsPct = currentReward.RequiredPointsPct;
            defaultValues.Image = currentReward.Image;
            reset(defaultValues)
        } else {
            defaultValues.TierName = '';
            defaultValues.TierPriority = 0;
            defaultValues.CompletionMessage = '';
        }
    };

    useEffect(() => {
        handleRewardSelected();
    }, [currentReward]);

    return (
        <>
            <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between' }}>
                <Box
                    component="form"
                    sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 1 }}>
                    <FormControl>
                        <FormInputText name="TierName" control={control} errors={errors} label="Tier name" disabled={true} />
                    </FormControl>
                    <FormControl>
                        <FormInputText name="TierPriority" control={control} errors={errors} label="Tier Priority" type='number' disabled={true} />
                    </FormControl>
                    <FormControl>
                        <FormInputText name="CompletionMessage" control={control} errors={errors} label="Completion Message" type='text' />
                    </FormControl>
                    <FormControl>
                        <FormInputText name="NextStepsMessage" control={control} errors={errors} label="Next Steps Message" type='text' />
                    </FormControl>
                    <FormControl>
                        <FormInputText name="RequiredChallengesPct" control={control} errors={errors} label="Required Challenges Percentage" type='number' multiline={true} />
                    </FormControl>
                    <FormControl>
                        <FormInputText name="RequiredPointsPct" control={control} errors={errors} label="Required Points Percentage" type='number' multiline={true} />
                    </FormControl>
                </Box>
            </Stack>
            <DialogActions>
                <Button
                    onClick={handleSubmit(onSubmit)}
                    variant="contained"
                    color="primary"
                >
                    Save Reward
                </Button>
            </DialogActions>
        </>
    );
}

export default RewardForm;