import { IActivity } from 'models/IActivity';

import { TextField } from '@mui/material';

import Grid from '@mui/material/Grid2';
import { styled } from '@mui/system';

const FormGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

interface IProps {
  activity: IActivity;
  handleChange: (e: EventTarget, b: boolean) => void;
}
function ConfirmationActivityForm(props: IProps) {
  return (
    <>
      <Grid container spacing={3} mt={2} >
        <FormGrid size={{ xs: 6 }}>
          <TextField
            id='waypoint-response-when-correct'
            label='Response Text'
            name='ResponseWhenCorrect'
            helperText=''
            variant='outlined'
            value={props.activity.ResponseWhenCorrect}
            onChange={e => props.handleChange(e.target, true)}
            fullWidth
          />
        </FormGrid>
        <FormGrid size={{ xs: 6 }}>
          <TextField
            id='waypoint-answer'
            label='Button Text'
            name='Answer'
            helperText=''
            variant='outlined'
            value={props.activity.Answer}
            onChange={e => props.handleChange(e.target, true)}
            fullWidth
          />
        </FormGrid>
      </Grid>
    </>
  );
}

export default ConfirmationActivityForm;
