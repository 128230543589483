import * as React from 'react';
import MuiAvatar from '@mui/material/Avatar';
import MuiListItemAvatar from '@mui/material/ListItemAvatar';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListSubheader from '@mui/material/ListSubheader';
import { selectClasses } from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import FormControl from '@mui/material/FormControl';
import TextField from "@mui/material/TextField";

const Avatar = styled(MuiAvatar)(({ theme }) => ({
  width: 28,
  height: 28,
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.secondary,
  border: `1px solid ${theme.palette.divider}`,
}));

const ListItemAvatar = styled(MuiListItemAvatar)({
  minWidth: 0,
  marginRight: 12,
});

interface CustomSelectProps {
  options: any;
  selectedOption: any;
  onChangeOption: (arg0: any) => void;
}

export default function CustomSelect({ options, selectedOption, onChangeOption }: CustomSelectProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const option = options.find((org: any) => org.Id === event.target.value);
    onChangeOption(option);
  };

  return (
    <>
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <TextField
          id="outlined-select-organization"
          select
          value={selectedOption.Id}
          onChange={(e) => handleChange(e as React.ChangeEvent<HTMLInputElement>)}
          label="Organizations"
          helperText="Please select an organization"
          slotProps={{
            select: {
              MenuProps: {
                sx: { maxHeight: "50%", width: "100px" },
              },
            },
          }}
          fullWidth
          sx={{
            maxHeight: 56,
            width: 300,
            '&.MuiList-root': {
              p: '8px',
            },
            [`& .${selectClasses.select}`]: {
              display: 'flex',
              alignItems: 'center',
              gap: '2px',
              pl: 1,
              pt: selectedOption ? 0.2 : 1.5,
              pb: selectedOption ? 0.2 : 1.5,
            },
          }}
        >
          <ListSubheader sx={{ pt: 0 }}>Organization</ListSubheader>
          {options.map((option: any, index: number) => (
            <MenuItem key={index} value={option.Id}>
              <ListItemAvatar>
                <Avatar alt={option.Name}>
                  <CorporateFareIcon sx={{ fontSize: '1rem' }} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={option.Name} secondary={option.Address} />
            </MenuItem>
          ))}
          <Divider sx={{ mx: -1 }} />
          <MenuItem value={40}>
            <ListItemIcon>
              <AddRoundedIcon />
            </ListItemIcon>
            <ListItemText primary="Add Organization" />
          </MenuItem>
        </TextField>
      </FormControl>
    </>
  );
}