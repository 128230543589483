import React, { useState}  from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import { IPark } from 'models/IPark';
import ConfirmationDialog from "helpers/ConfirmationDialog";
import { deletePark } from "services/Parks.Service";



interface ParkComponentProps {
    park: IPark;
    authContext: any;
    onEdit: (selectedPark: IPark) => void;
    onDelete: (selectedPark: IPark) => void;
}

export default function ParkMenu({ park, onEdit, onDelete, authContext }: ParkComponentProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEditClick = () => {
        onEdit(park); 
        handleClose();
    };

    const showConfirmDialog = (): void => {
        setShowConfirmation(true);
    };

    const handleConfirmDialogClose = (): void => {
        setShowConfirmation(false);        
    };

    const handleDelete = (): void => {        
        deletePark(authContext, park).then((_data) => {
            onDelete(park);
            setShowConfirmation(false);
            handleClose();
        });
    };

    return (
        <React.Fragment>
            <Tooltip title="Park options">
                <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <MoreVertIcon />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&::before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleEditClick}> {/* Call handleEditClick */}
                    <ListItemIcon>
                        <EditIcon color='primary' fontSize="small" />
                    </ListItemIcon>
                    Edit
                </MenuItem>
                <MenuItem onClick={showConfirmDialog}>
                    <ListItemIcon>
                        <DeleteIcon color='error' fontSize="small" />
                    </ListItemIcon>
                    Delete
                </MenuItem>
            </Menu>
            <ConfirmationDialog
                open={showConfirmation}
                close={handleConfirmDialogClose}
                confirm={handleDelete}
                warning={false}
            />
        </React.Fragment>
    );
}
