import { createSlice } from '@reduxjs/toolkit';

const orgnizationSlice = createSlice({
  name: 'organization',
  initialState: { orgId: null },
  reducers: {
    setOrg: (state, action) => {
      console.log('organization', action.payload);
      state.orgId = action.payload;
    },
    getOrg: (state) => {
      state.value -= 1;
    },
  },
});

export const { setOrg, getOrg } = orgnizationSlice.actions;
export default orgnizationSlice.reducer;