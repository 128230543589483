import { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import AuthContext from "context/Auth/auth";
import RewardCard from "../cms/common/RewardCard";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { getRewardTiers, updateRewardTiers } from "services/Parks.Service";
import { getZones } from "services/ZonesService";
import { getPoints } from "services/Waypoint.Service";
import reWardGoldImage from "../../../../images/cms/badges/reward_gold.png";
import reWardSilverImage from "../../../../images/cms/badges/reward_silver.png";
import reWardBronzeImage from "../../../../images/cms/badges/reward_bronze.png";
import RewardForm from "../cms/forms/RewardForm";

import { makeStyles } from "@mui/styles";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  modal: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    borderRadius: "10px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    margin: "0 auto",
  },
  formContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "10px 10px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  media: {
    height: 340,
  },
}));

function RewardsTierTab({ parkId, triggerSnack, setMessage, setSeverity }) {
  const context = useContext(AuthContext);
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [rewards, setRewards] = useState([]);
  const [isDefault, setIsDefault] = useState(false);

  const [currentReward, setCurrentReward] = useState({});
  const [maxNumberOfRewards, setMaxNumberOfRewards] = useState(0);
  const [currentNumberOfRewards, setCurrentNumberOfRewards] = useState(0);
  const [rewardSetting, setRewardSetting] = useState("default");
  const [updatedRewardTier, setUpdatedRewardTier] = useState(null);
  const [showConfirmationForReward, setShowConfirmationForReward] =
    useState(false);

  // MODAL
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleRewardChange = (event) => {
    setRewardSetting(event.target.value);
  };

  const handleFormUpdate = ({ name, value }) => {
    setCurrentReward({ ...currentReward, [name]: value });
  };

  const handleBadgeEdit = (event, reward) => {
    event.preventDefault();
    setCurrentReward(reward);
    setOpen(true);
  };

  const confirmMenuClose = () => {
    handleMenuClose();
  };

  const handleMenuClose = () => {
    setOpen(false);
    setCurrentReward({});
  };

  const handleRewardsSave = (current) => {
    setCurrentReward(current);
    setLoading(true);
    setOpen(false);

    // Make a copy of rewards to avoid mutating state directly
    let rewardsCopy = [...rewards];
    // If currentReward has no Id, add it to the rewards list
    if (!current.Id) {
      setRewards((prevRewards) => [...prevRewards, current]);
      rewardsCopy.push(current); // Add the currentReward to the copy for further processing
    } else {
      // If currentReward has an Id, update the existing reward
      const updatedReward = rewardsCopy.find((r) => r.Id === current.Id);
      if (updatedReward) {
        const indexForReward = rewardsCopy.indexOf(updatedReward);
        rewardsCopy.splice(indexForReward, 1, current);
      }
    }

    // Handle the case where isDefault is true (for creating new rewards)
    if (isDefault) {
      rewardsCopy.forEach((reward) => {
        delete reward.Id; // Remove Id to create new rewards
      });
    }

    // Clean up reward data before sending it to the backend
    rewardsCopy.forEach((reward) => {
      delete reward.ParkId; // Remove parkId from the reward
      reward.RequiredChallengesPct = parseInt(reward.RequiredChallengesPct);
      reward.RequiredPointsPct = parseInt(reward.RequiredPointsPct);
      reward.TierPriority = parseInt(reward.TierPriority);
    });

    // Update the rewards
    updateRewardTiers(context, parkId, rewardsCopy)
      .then((response) => {
        setIsDefault(false);
        setRewards(rewardsCopy); // Update state with the updated rewards
        setCurrentReward({}); // Reset the current reward
        setMessage(response.data.Message);
        setSeverity("success");
        triggerSnack(true);
        setOpen(false);
        setLoading(false);
        //setUpdatedRewardTier(rewardsCopy);
      })
      .catch((error) => {
        console.error(error);
        setMessage("Error Occurred");
        setSeverity("error");
        triggerSnack(true);
        setOpen(false);
        setLoading(false);
      });
  };

  const handleRewardsDelete = () => {
    setLoading(true);
    setOpen(false);

    // Make a copy of rewards to avoid mutating state directly
    let rewardsCopy = [...rewards];

    // Check if the current reward has an Id (for deleting existing rewards)
    if (currentReward.Id) {
      // Filter out the currentReward from the rewards list
      rewardsCopy = rewardsCopy.filter(
        (reward) => reward.Id !== currentReward.Id
      );
    }

    // Clean up reward data before sending it to the backend
    rewardsCopy.forEach((reward) => {
      delete reward.ParkId; // Remove ParkId from the reward
      reward.RequiredChallengesPct = parseInt(reward.RequiredChallengesPct);
      reward.RequiredPointsPct = parseInt(reward.RequiredPointsPct);
      reward.TierPriority = parseInt(reward.TierPriority);
    });

    updateRewardTiers(context, parkId, rewardsCopy)
      .then((response) => {
        setRewards(rewardsCopy); // Update state with the remaining rewards
        setCurrentReward({}); // Reset the current reward
        setMessage(response.data.Message);
        setSeverity("success");
        triggerSnack(true);
        setOpen(false);
        setLoading(false);
        setShowConfirmationForReward(false);
      })
      .catch((error) => {
        console.error(error);
        setMessage("Error Occurred");
        setSeverity("error");
        triggerSnack(true);
        setOpen(false);
        setLoading(false);
        setShowConfirmationForReward(false);
      });
  };

  const handleConfirmDialogClose = () => {
    setShowConfirmationForReward(false);
  };

  const handleConfirmDialogOpen = () => {
    if (currentReward.Id !== null) {
      handleClose();
      setShowConfirmationForReward(true);
    }
  };

  const imageToBase64 = async (imageUrl) => {
    const response = await fetch(imageUrl);
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result); // Base64 string
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  useEffect(() => {
    const loadRewardsData = async () => {
      setLoading(true); // Set loading to true at the start

      try {
        const reducer = (accumulator, currentValue) =>
          accumulator + currentValue;

        // Get Max Number of Reward Possible
        const zonesResponse = await getZones(context, parkId);
        const activeGameZones = zonesResponse.Hunts;
        const activeGamePoints = [];

        // Create an array of promises for fetching points from all zones
        const pointsPromises = activeGameZones.map(async (agz) => {
          const pointsResponse = await getPoints(context, {
            ParkId: parkId,
            ZoneId: agz.HuntId,
          });
          activeGamePoints.push(pointsResponse.Points.length);
        });

        // Wait for all point requests to finish
        await Promise.all(pointsPromises);

        // Set Max Number of Rewards
        setMaxNumberOfRewards(activeGamePoints.reduce(reducer, 0));

        // Get Reward Tiers
        const rewardTiersResponse = await getRewardTiers(context, parkId);
        const rewards = rewardTiersResponse.data;

        // Process rewards and fetch images
        await Promise.all(
          rewards.map(async (reward) => {
            if (reward.ParkId === -1) {
              reward.ParkId = parkId;
              setIsDefault(true);
            }

            switch (reward.TierName) {
              case "Gold":
                reward.Image = await imageToBase64(reWardGoldImage);
                break;
              case "Silver":
                reward.Image = await imageToBase64(reWardSilverImage);
                break;
              case "Bronze":
                reward.Image = await imageToBase64(reWardBronzeImage);
                break;
              default:
                reward.Image = null;
                break;
            }
          })
        );

        setRewards(rewards);
        setCurrentNumberOfRewards(rewards.length);

        // Handle reward settings
        if (rewards.length > 3) {
          setRewardSetting("per-point");
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false); // Ensure loading is set to false once everything is done
      }
    };

    // Call the async function
    loadRewardsData();
  }, [context, parkId]);

  useEffect(() => {
    if (updatedRewardTier) {
      setLoading(true);
      updateRewardTiers(context, parkId, rewards)
        .then((response) => {
          setIsDefault(false);
          setRewards(rewards); // Update rewards again if necessary
          setCurrentReward({});
          setMessage(response.data.Message);
          setSeverity("success");
          triggerSnack(true);
          setOpen(false);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setMessage("Error Occurred");
          setSeverity("error");
          triggerSnack(true);
          setOpen(false);
          setLoading(false);
        });
    }
  }, [updatedRewardTier, rewards]);

  return (
    <>
      {loading ? (
        <Grid container justify="center" alignItems="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={1}>
          <Grid item size={12}>
            <h1>Explorer Quest Rewards</h1>

            <Grid
              container
              spacing={2}
              justify="space-between"
              alignItems="center"
            >
            </Grid>
          </Grid>

          {rewards.map((reward) => {
            return (
              <Grid item xs={12} sm={4} key={reward.Id}>
                <RewardCard
                  imgSrc={reward.Image}
                  tierName={reward.TierName}
                  completionMessage={reward.CompletionMessage}
                  nextStepsMessage={reward.NextStepsMessage}
                  onBadgeEdit={handleBadgeEdit}
                  rewardTier={reward}
                />
              </Grid>
            );
          })}
        </Grid>
      )}

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle>Reward Tier</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleMenuClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <RewardForm 
          currentReward={currentReward}
          callBackRewardsSave={handleRewardsSave}/>
        </DialogContent>
      </Dialog>
    </>
  );
}

RewardsTierTab.propTypes = {
  parkId: PropTypes.number.isRequired,
  triggerSnack: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  setSeverity: PropTypes.func.isRequired,
};

export default RewardsTierTab;
